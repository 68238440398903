html {
    height: 100%;
    background-attachment: fixed;
    will-change: background;
}

body {
    min-width: 1280px;
}

.gradient-background.ios {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    will-change: background;
}
