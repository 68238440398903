@mixin col-common {
    @include flex-grow(0);
    @include flex-shrink(0);
}

.wrapper {
    width: $grid-width;
    margin: 0 auto;
}

.row {
    box-sizing: border-box;
    @include flexbox();
    @include flex(0, 1, auto);
    @include flex-direction(row);
    @include flex-wrap(wrap);

    &.reverse {
        @include flex-direction(row-reverse);
    }

    &__column {
        @include flex-grow(1);
        @include flex-basis(0);

        max-width: 100%;
    }

    @for $i from 1 through $grid-columns {

        &__column-#{$i} {
            @include col-common;
            @include flex-basis(calc(100% / $grid-columns) * $i);
    
            max-width: calc(100% / $grid-columns) * $i;
        }
    }    
}

.d-flex {
    @include flexbox();

    &.align-items-center {
        @include align-items(center);
    }
    
    &.justify-content-center {
        @include justify-content(center);
    }
}
