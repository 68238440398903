@include fontface("Fira GO", "FiraGO-Regular", 400);
@include fontface("Fira GO", "FiraGO-ExtraBold", 800);

html {
    color: $text-color-main;
    font-family: "Fira GO", sans-serif;
    font-size: $font-size-base;
    font-weight: 400;
}

body {
    line-height: $line-height-base;
}

.section-subheader, p {
    margin-bottom: $baseline;
}

.section-header {
    font-size: 2rem;
    font-weight: 800;
    margin-bottom: $baseline * 2;
}

.section-subheader, b, strong {
    font-weight: 800;
}

.section-subheader {
    margin-top: $baseline * 4;
}

a {
    color: $text-color-link;
}
